<template>
    <v-container>
        <v-row>
            <v-col cols="6" offset="3">
                <v-card>

                    <v-form @submit.prevent="save" ref="lightboxForm">
                        <v-card-text>
                            <v-text-field
                                    id="lightboxNameField"
                                    :label="$t('lightbox')"
                                    v-model="lightboxDetails.name"
                                    :rules="lightboxRules"
                                    required
                            />
                        </v-card-text>

                      <v-card-text>
                        <v-text-field
                            id="lightboxOpenField"
                            type="date"
                            :label="$t('date available from')"
                            v-model="lightboxDetails.date_open"

                        />
                      </v-card-text>
                      <v-card-text>
                        <v-text-field
                            id="lightboxCloseField"
                            type="date"
                            :label="$t('date available till')"
                            v-model="lightboxDetails.date_close"

                        />
                      </v-card-text>
                        <v-card-actions>
                            <v-btn id="deleteButton" color="error" @click="areyousure = true">
                                <v-icon dark left>mdi-delete-alert</v-icon>
                                {{ $t('delete') }}
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn id="sendButton" color="success" @click="sendLightbox = true">
                                <v-icon dark left>mdi-at</v-icon>
                                {{ $t('send') }}
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn id="submitButton" type="submit" color="info">
                                <v-icon dark left>mdi-send</v-icon>
                                {{ $t('save') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>

                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="sendLightbox" persistent width="50%">
            <v-form @submit.prevent="send" ref="messageForm">

                <v-card>
                    <v-card-title>{{ $t('send_lightbox') }}</v-card-title>

                    <v-card-text>
                        <v-textarea
                                :label="$t('message')"
                                v-model="message">
                        </v-textarea>

                        <v-text-field
                                :label="$t('email')"
                                v-model="email"
                                :rules="emailRules"
                                required
                        />
                    </v-card-text>

                    <v-card-actions>
                        <v-btn color="error"
                               @click="sendLightbox = false"
                               v-shortkey="['esc']" @shortkey="sendLightbox = false"
                        >
                            <v-icon dark left>mdi-cancel</v-icon>
                            {{ $t('cancel') }}
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn type="submit" color="info">
                            <v-icon dark left>mdi-send</v-icon>
                            {{ $t('send') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-form>
        </v-dialog>

        <v-dialog v-model="areyousure" persistent width="25%">
            <v-card>
                <v-card-title>{{ $t('are_you_sure') }}</v-card-title>

                <v-card-text>
                    {{ $t('are_you_sure_delete_lightbox') }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue" text
                           @click="areyousure = false"
                           v-shortkey="['esc']" @shortkey="areyousure = false"
                    >
                        {{ $t('cancel') }}
                    </v-btn>

                    <v-btn color="blue" text @click="remove">{{ $t('ok') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

      <v-alert
          border="left"
          color="orange"
          type="warning"
          v-if="lightboxDetails.isSpecial">
        {{ $t('warning_confidential_content')}}
      </v-alert>
      <v-alert
          border="left"
          color="blue"
          type="info"
          v-if="!lightboxDetails.isOpen">
        This selection is not visible yet. Please come back later!
      </v-alert>
      <v-alert
          border="left"
          color="red"
          type="info"
          v-if="lightboxDetails.isClosed">
        Sorry, this selection is not available anymore!
      </v-alert>

        <ImagesList v-if="images" :images="images" :groups="groups" page="lightbox"></ImagesList>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import {mapState} from 'vuex'
    import lightboxService from '@/services/LightboxService.js'
    import ImagesList from '@/components/ImagesList'

    export default {
        name: "Lightbox",

        components: {
          ImagesList,
        },

        computed: {
            ...mapState(['isLoggedIn', 'user', 'lightbox']),
        },

        created() {
            // Route guard
            if (!this.isLoggedIn) {
                this.$store.dispatch('logout')
                this.$router.push('/login')
            }

            this.init()
        },

        data() {
            return {
                lightboxDetails: {
                    id: 0,
                    name: '',
                },

                groups: [],
                images: [],
                selectedGroup:0,

                email: '',
                message: '',

                lightboxRules: [
                    value => !!value || this.$t('required'),
                ],

                emailRules: [
                    value => !!value || this.$t('required'),
                    value => value.includes('@') || this.$t('email_invalid'),
                ],

                areyousure: false,
                sendLightbox: false,

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',
            }
        },

        methods: {
            init() {
                // Fetch current lightbox from store
                this.lightboxDetails = this.lightbox

                lightboxService.items(this.lightbox)
                    .then(response => {
                        this.groups = response.data['groups'];
                        this.images = response.data['images'];
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('lightbox_empty')
                    })
            },

            save() {
                if (this.$refs.lightboxForm.validate()) {
                    lightboxService.save(this.lightboxDetails)
                        .then(() => {
                            this.snackbar = true
                            this.snackbarColor = "success"
                            this.snackbarMessage = this.$t('data_saved')
                        })
                        .catch(() => {
                            this.snackbar = true
                            this.snackbarColor = "error"
                            this.snackbarMessage = this.$t('invalid_data')
                        })
                }
            },

            send() {
                if (this.$refs.messageForm.validate()) {
                    this.sendLightbox = false

                    lightboxService.send(
                        {
                            id: this.lightboxDetails.id,
                            user_id: this.user.id,
                            message: this.message,
                            email: this.email,
                        }
                    )
                        .then(() => {
                            this.snackbar = true
                            this.snackbarColor = "success"
                            this.snackbarMessage = this.$t('lightbox_sent')
                        })
                        .catch(() => {
                            this.snackbar = true
                            this.snackbarColor = "error"
                            this.snackbarMessage = this.$t('invalid_data')
                        })
                }
            },

            remove() {
                this.areyousure = false

                lightboxService.remove(this.lightboxDetails)
                    .then(() => {
                        // Redirect to lightboxes
                        this.$router.push('/lightboxes')
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },
        },
    }
</script>
